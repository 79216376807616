<template>
  <div class="ma-5">
      <v-row dense align="center"  align-content="center" justify="center">
        <v-col cols="12" sm="6" v-for="img in curSingles.imgs" :key="img.id">
        <v-img contain :src="`../${curSingles.folder}/${img.img}`"></v-img>
        </v-col>
      </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
// import axios from "axios";
import { mapState } from 'vuex';

// const APIURL = ''

export default {
  name: "Singles",
  data: () => ({
    //
  }),
  props: {
    type: String,
  },
  computed: {
    ...mapState(['singleDocFolder', 'singleFaPortFolder', 'singleDoc', 'singleFaPort']),
    curSingles(){
      const singles = {};
        switch(this.type){
            case 'documentaries':
              singles.imgs = [...this.singleDoc];
              singles.folder = this.singleDocFolder;
              break;
            case 'fashion-portraits':
              singles.imgs = [...this.singleFaPort];
              singles.folder = this.singleFaPortFolder;
              break;
            default:
              singles.imgs = [...this.singleDoc];
              singles.folder = this.singleDocFolder;
              break;
        }
      return singles;
    },
  }  
};
</script>
